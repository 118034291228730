import React, { useState } from 'react';
import Order from '../order/Order';
import { Input, Button, Header, Message, Form } from 'semantic-ui-react';
import Axios from 'axios';
import { api, fixedpassword } from '../../config';
import { connect } from 'react-redux';

function Login(props) {
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');

  const [loading, setloading] = useState(false);

  const [error, seterror] = useState(null);

  function login() {
    setloading(true);

    Axios.post(api + 'users/', { username, password }, { timeout: 3000 })
      .then(res => {
        localStorage.setItem('user', JSON.stringify(res.data[0]));
        props.history.push('/');
        props.dispatch({ type: 'LOGIN_USER', value: res.data[0] });
      })
      .catch(e => {
        
        e.response
          ? seterror('Wrong username or password.')
          : seterror(e.message);
        setTimeout(() => {
          seterror(null);
        }, 4000);
      })
      .finally(() => {
        setloading(false);
      });
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#00b5ad',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Header
        style={{
          textAlign: 'center',
          color: 'white',
          fontSize: 40,
          position: 'absolute',
          top: '5%'
        }}
      >
        ALFOZ SUPERVISORS
      </Header>
      {error && (
        <Message style={{ position: 'absolute', bottom: '2%' }} color='red'>
          {error}
        </Message>
      )}

      <Form onSubmit={login}>
        <Form.Field>
          <Input
            onChange={(e, data) => {
              setusername(data.value);
            }}
            value={username}
            iconPosition='left'
            icon='user'
            placeholder='login'
          ></Input>
        </Form.Field>
        <Form.Field>
          <Input
            onChange={(e, data) => {
              setpassword(data.value);
            }}
            type='password'
            value={password}
            icon='key'
            iconPosition='left'
            placeholder='password'
          ></Input>
        </Form.Field>
        <Form.Field style={{ textAlign: 'center' }}>
          <Button type='submit' loading={loading} inverted color='black'>
            Connect
          </Button>
        </Form.Field>
      </Form>
    </div>
  );
}

export default connect()(Login);
