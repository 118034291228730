import React, { useState, useEffect, useRef } from 'react';
import { Menu, Label, Header } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faBell,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import './navbar.scss';

import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { api } from '../../config';

import { connect } from 'react-redux';

function Navbar(props) {
  let history = useHistory();

  const [nborders, setnborders] = useState(0);
  const [nbinitial, setnbinitial] = useState(0);

  function redirect() {
    history.push('/');
  }

  function logout() {
    localStorage.removeItem('user');
    props.dispatch({ type: 'LOGOUT_USER' });
    history.push('/login');
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }

      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

  function getCountOrders(firsttime) {
    props.currentUser.Code_Utilisateur &&
      Axios.get(
        api + 'order/countpendingorders/' + props.currentUser.Code_Utilisateur
      ).then((res) => {
        firsttime === true && setnbinitial(res.data[0].count);
        setnborders(!firsttime ? res.data[0].count - nbinitial : 0);
      });
  }

  useInterval(() => {
    getCountOrders(false);
  }, 5000);

  useEffect(() => {
    getCountOrders(true);
  }, []);

  return (
    <Menu borderless size='massive'>
      <Menu.Item
        icon={<FontAwesomeIcon icon={faUserCircle} size='2x'></FontAwesomeIcon>}
        className='tealcolor'
      ></Menu.Item>

      <Menu.Item
        as={Header}
        className='name'
        name={props.currentUser && props.currentUser.Code_Utilisateur}
      ></Menu.Item>

      <Menu.Menu position='right'>
        {/* <Menu.Item
          style={{ cursor: 'pointer' }}
          onClick={redirect}
          icon={
            <div>
              <FontAwesomeIcon size='2x' icon={faBell} />
              {nborders > 0 && (
                <Label className='badge' circular color='red'>
                  {' '}
                  {nborders}
                </Label>
              )}
            </div>
          }
          className='tealcolor'
        ></Menu.Item> */}

        <Menu.Item
          onClick={logout}
          className='tealcolor'
          icon={
            <FontAwesomeIcon icon={faSignOutAlt} size='2x'></FontAwesomeIcon>
          }
        ></Menu.Item>
      </Menu.Menu>
    </Menu>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(Navbar);
