import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListOrders from './ListOrders';
import { Container } from 'semantic-ui-react';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
});

export default function Home() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container
        style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}
      >
        <Paper className={classes.root}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label='Pending offers' />
            <Tab label='History' />
          </Tabs>
        </Paper>
      </Container>
      <ListOrders value={value}></ListOrders>
    </>
  );
}
