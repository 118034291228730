import Navbar from "../containers/navbar/Navbar";
import React from "react";

const AppLayout = props => {
  return (
    <>
      {props.navbar === false ? null : <Navbar />}
      {props.children}
    </>
  );
};

export default AppLayout;
