import React, { useEffect, useState } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import Home from './containers/home/Home';
import Order from './containers/order/Order';
import Login from './containers/login/Login';
import AppRoute from './components/AppRoute';
import ProtectedAppRoute from './components/ProtectedAppRoute';
import LoginAppRoute from './components/LoginAppRoute';
import { connect } from 'react-redux';

function Routing(props) {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    props.dispatch({ type: 'LOGIN_USER', value: user });
  }, []);

  return (
    <Switch>
      <LoginAppRoute path='/login' component={Login} navbar={false} />
      <ProtectedAppRoute path='/order/:id' component={Order} />
      <ProtectedAppRoute user={props.currentUser} path='/' component={Home} />
      <ProtectedAppRoute render={() => <Redirect to='/' />} />
    </Switch>
  );
}

export default connect()(Routing);
