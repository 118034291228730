import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  List,
  Header,
  Statistic,
  Button,
  Icon,
  Message,
  Popup,
  Grid
} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faPen,
  faTimes,
  faUndoAlt,
  faUser,
  faStore
} from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { api } from '../../config';
import MyActivityIndicator from '../../components/myActivityIndicator';

function getOrder(
  props,
  setoriginalorder,
  setorder,
  setisLoading,
  settotalprice,
  order
) {
  Axios.get(api + 'order/' + props.match.params.id).then(res => {
    setorder(res.data);
    setoriginalorder(res.data);
    setisLoading(false);
    res.data.length === 0 &&
      setInterval(() => {
        props.history.push('/');
      }, 3000);
  });
}

export default function Order(props) {
  const [deletedLines, setdeletedLines] = useState([]);
  const [editedLines, seteditedLines] = useState([]);
  const [order, setorder] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [edit, setedit] = useState(null);
  const [originalorder, setoriginalorder] = useState([]);
  const [saving, setsaving] = useState(null);

  const [loadingprice, setloadingprice] = useState(false);
  const [error, seterror] = useState(false);
  const [totalprice, settotalprice] = useState(null);

  useEffect(
    () => getOrder(props, setoriginalorder, setorder, setisLoading),
    []
  );

  useEffect(() => {
    order &&
      settotalprice(
        Math.round(
          order.reduce(
            (totalPrice, e) => totalPrice + e.PrixUnitaire * e.Quantite,
            0
          ) * 100
        ) / 100
      );
  }, [order]);

  async function saveOrder(decision) {
    if (
      window.confirm(
        `Are you sure you want to ${
          decision ? 'accept' : 'reject'
        } this order? You will not be able to edit it anymore.`
      )
    ) {
      try {
        setsaving('saving');
        await Axios.put(
          api + 'order/' + props.match.params.id,
          {
            accepted: decision,
            editedLines,
            deletedLines,
            totalprice,
			user: JSON.parse(localStorage.getItem('user')).Code_Utilisateur
          },
          { timeout: 3000 }
        );
        setsaving('accepted');
        setTimeout(() => {
          props.history.push('/');
        }, 3000);
      } catch (e) {
        console.log(e);
        setsaving(e.message);
      }
    }
  }

  async function getPrice(article, quantity, i) {
    try {
      const price = await Axios.get(
        api + 'order/price/' + article + '/' + quantity,
        { timeout: 3000 }
      );

      seteditedLines(
        editedLines.findIndex(e => e.line === order[i].NumLigne) === -1
          ? [
              ...editedLines,
              {
                line: order[i].NumLigne,
                quantity: parseFloat(quantity),
                PrixUnitaire: parseFloat(
                  Math.round((price.data / quantity) * 100) / 100
                )
              }
            ]
          : editedLines.map(e =>
              e.line === order[i].NumLigne
                ? {
                    ...e,
                    quantity: parseFloat(quantity),
                    PrixUnitaire: parseFloat(
                      Math.round((price.data / quantity) * 100) / 100
                    )
                  }
                : e
            )
      );

      setorder(
        order.map((p, index) =>
          index === i
            ? {
                ...p,
                Quantite: quantity,
                PrixUnitaire: Math.round((price.data / quantity) * 100) / 100
              }
            : p
        )
      );
      setloadingprice(false);
      seterror(false);
    } catch (e) {
      console.log(e);
      seterror(true);
    }
  }

  function deleteArticle(i) {
    if (
      window.confirm(
        'Are you sure you want to delete this article ? You will not be able to add it again.'
      )
    ) {
      setdeletedLines([...deletedLines, order[i].NumLigne]);
      const neworder = order.filter((value, index) => index !== i);
      setorder(neworder);
    }
  }

  function editQuantity(quantity, i) {
    setloadingprice(true);

    setorder(
      order.map((p, index) =>
        index === i
          ? {
              ...p,
              Quantite: quantity,
              PrixUnitaire: null
            }
          : p
      )
    );

    !isNaN(parseFloat(quantity)) &&
      getPrice(order[i].Code_Article, quantity, i);
  }    
  return (
    <Container>
      {isLoading ? (
        <MyActivityIndicator />
      ) : order && order.length > 0 ? (
        <>
          {error && (
            <Message color='red' icon>
              <Icon name='times' />
              <Message.Content>
                <Message.Header>
                  Error while getting price. Please retry again.
                </Message.Header>
              </Message.Content>
            </Message>
          )}
          {saving &&
            (saving === 'saving' ? (
              <Message icon>
                <Icon name='circle notched' loading />
                <Message.Content>
                  <Message.Header>Saving order...</Message.Header>
                </Message.Content>
              </Message>
            ) : saving === 'accepted' ? (
              <Message color='green' icon>
                <Icon name='check' />
                <Message.Content>
                  <Message.Header>
                    Order saved! You will be redirected to the menu in a few
                    seconds.
                  </Message.Header>
                </Message.Content>
              </Message>
            ) : (
              <Message color='red' icon>
                <Icon name='times' />
                <Message.Content>
                  <Message.Header>Error while saving order...</Message.Header>
                  <Message.Content>{saving}</Message.Content>
                </Message.Content>
              </Message>
            ))}
          <Card fluid>
            <Card.Content>
              <Card.Header
                style={{
                  color: '#00b5ad',
                  marginBottom: 20
                }}
                textAlign='center'
              >
                <div style={{ cursor: 'pointer', position: 'absolute' }}>
                  <FontAwesomeIcon
                    onClick={() => props.history.goBack()}
                    size='lg'
                    icon={faArrowLeft}
                  ></FontAwesomeIcon>
                </div>
                {saving !== 'accepted' &&
                  saving !== 'saving' &&
                  order[0].status === 'pending' && (
                    <div
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: 10
                      }}
                    >
                      <Popup
                        on={['hover', 'focus']}
                        position='bottom center'
                        content='Reset order modifications'
                        trigger={
                          <FontAwesomeIcon
                            onClick={() => {
                              setorder(originalorder);
                              seteditedLines([]);
                              setdeletedLines([]);
                            }}
                            size='lg'
                            icon={faUndoAlt}
                          ></FontAwesomeIcon>
                        }
                      ></Popup>
                    </div>
                  )}
                Order #{props.match.params.id}
              </Card.Header>
              <Card.Meta style={{ marginBottom: 30 }}>
                <Container>
                  <Grid>
                    <Grid.Row columns={2} style={{ padding: 5 }}>
                      <Container>
                        <Grid>
                          <Grid.Column textAlign='right' width={8}>
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                          </Grid.Column>
                          <Grid.Column textAlign='left' width={7}>
                            {order[0].Nom}
                          </Grid.Column>
                        </Grid>
                      </Container>
                    </Grid.Row>
                    <Grid.Row style={{ padding: 5 }}>
                      <Container>
                        <Grid>
                          <Grid.Column textAlign='right' width={8}>
                            <FontAwesomeIcon icon={faStore}></FontAwesomeIcon>
                          </Grid.Column>
                          <Grid.Column width={7} textAlign='left'>
                            {order[0].RaisonSociale}
							<br />
							<a target='_blank' href={'https://www.openstreetmap.org/?mlat='+ order[0].ZoneN4 +'&mlon='+order[0].ZoneN5+'#map=11/'+order[0].ZoneN4+'/'+order[0].ZoneN5}>GPS MAP</a>
								({order[0].ZoneN4}/{order[0].ZoneN5})
                          </Grid.Column>
                        </Grid>
                      </Container>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Card.Meta>
              <Card.Meta textAlign='center'>
                {saving !== 'accepted' &&
                  saving !== 'saving' &&
                  order[0].status === 'pending' && (
                    <>
                      <Button
                        style={{ marginBottom: 10 }}
                        disabled={error || loadingprice}
                        onClick={() => saveOrder(true)}
                        color='teal'
                        icon
                        labelPosition='left'
                      >
                        <Icon name='check'></Icon>Accept order
                      </Button>
                      <Button
                        disabled={error || loadingprice}
                        onClick={() => saveOrder(false)}
                        color='red'
                        icon
                        labelPosition='left'
                      >
                        <Icon name='times'></Icon>Reject order
                      </Button>
                    </>
                  )}
              </Card.Meta>
              <Card.Description
                style={{ marginTop: 30, borderTop: '1px solid #00b5ad' }}
              >
                <List divided verticalAlign='middle' relaxed>
                  {order.map((article, i) => (
                    <List.Item
                      key={article.Code_Article}
                      style={{ padding: 20 }}
                    >
                      <List.Content floated='right'>
                        {article.PrixUnitaire ? (
                          article.statusarticle === 'deleted' ? (
                            <Header color='red'>DELETED</Header>
                          ) : (
                            <Statistic size='tiny'>
                              <Statistic.Value>
                                {Math.round(
                                  article.Quantite * article.PrixUnitaire * 100
                                ) /
                                  100 +
                                  ' LYD'}
                              </Statistic.Value>
                              <Statistic.Label>
                                {article.PrixUnitaire}LYD / unit
                              </Statistic.Label>
                            </Statistic>
                          )
                        ) : (
                          <MyActivityIndicator color='grey' />
                        )}
                      </List.Content>
                      <Header as='h3' color='teal'>
                        {article.Designation}
                        {
                          <div style={{ color: 'black', display: 'inline' }}>
                            &nbsp; &nbsp; {' x'}
                            {edit === i ? (
                              <input
                                style={{ width: 70 }}
                                value={article.Quantite}
                                onChange={e => editQuantity(e.target.value, i)}
                                type='number'
                              ></input>
                            ) : (
                              article.Quantite
                            )}
                          </div>
                        }
                        <Header.Subheader style={{ fontWeight: 'normal' }}>
                          {'Article code: ' + article.Code_Article}
                        </Header.Subheader>
                        {order[0].status === 'pending' ? (
                          <Header.Subheader>
                            <Button
                              onClick={() => {
                                edit === i ? setedit(null) : setedit(i);
                              }}
                              color='teal'
                              icon
                              style={{ padding: 5 }}
                            >
                              <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                            </Button>
                            {order.length > 1 && (
                              <Button
                                onClick={() => deleteArticle(i)}
                                color='red'
                                icon
                                style={{
                                  padding: 5,
                                  paddingLeft: 7,
                                  paddingRight: 7
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                ></FontAwesomeIcon>
                              </Button>
                            )}
                          </Header.Subheader>
                        ) : (
                          order[i].statusarticle !== 'deleted' &&
                          order[i].QuantiteInitiale !== order[i].Quantite && (
                            <Header.Subheader style={{ color: 'orange' }}>
                              EDITED - Initial quantity :{' '}
                              {order[i].QuantiteInitiale}
                            </Header.Subheader>
                          )
                        )}
                      </Header>
                    </List.Item>
                  ))}
                  <List.Item style={{ padding: 20 }} key='total'>
                    <List.Content floated='right'>
                      {!loadingprice && (
                        <Statistic size='tiny'>
                          <Statistic.Value style={{ fontWeight: 'bold' }}>
                            {!isLoading &&
                              (order[0].status !== 'pending'
                                ? order[0].MontantAcompte + ' LYD'
                                : totalprice + ' LYD')}
                          </Statistic.Value>
                        </Statistic>
                      )}
                    </List.Content>
                    <List.Content>
                      <Header as='h2'>TOTAL : </Header>
                    </List.Content>
                  </List.Item>
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        </>
      ) : (
        <Message>
          No orders with this id. You will be redirected to homepage.
        </Message>
      )}
    </Container>
  );
}
