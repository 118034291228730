import React, { useEffect, useState } from 'react';
import {
  List,
  Container,
  Search,
  Label,
  Button,
  Message,
} from 'semantic-ui-react';

import './home.scss';
import Order from './Order/order';
import Axios from 'axios';
import MyActivityIndicator from '../../components/myActivityIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DatesRangeInput } from 'semantic-ui-calendar-react';

import { connect } from 'react-redux';

const { api } = require('../../config.js');

function getOrders(
  currentUser,
  dates,
  value,
  setisLoading,
  setorders,
  setisGettingData,
  setlastupdate,
  setdisplayedorders
) {
  setisLoading(true);

  //pending orders
  value === 0
    ? Axios.get(
        api +
          'order/pendingorders/' +
          currentUser.Code_Utilisateur +
          '/' +
          moment(dates.split('-')[0], 'DD/MM/YYYY').format('YYYYMMDD') +
          '/' +
          moment(dates.split('-')[1], 'DD/MM/YYYY')
            .add(1, 'day')
            .format('YYYYMMDD')
      )
        .then((res) => {
          setisGettingData(false);
          setisLoading(false);
          setdisplayedorders(res.data);
          setorders(res.data);
          setlastupdate(moment().format('DD/MM/YYYY, HH:mm:ss'));
        })
        .catch((err) => console.log(err))
    : Axios.get(
        api +
          'order/orders/' +
          currentUser.Code_Utilisateur +
          '/' +
          moment(dates.split('-')[0], 'DD/MM/YYYY').format('YYYYMMDD') +
          '/' +
          moment(dates.split('-')[1], 'DD/MM/YYYY')
            .add(1, 'day')
            .format('YYYYMMDD')
      )
        .then((res) => {
          setisLoading(false);
          setisGettingData(false);
          setdisplayedorders(res.data);
          setorders(res.data);
          setlastupdate(moment().format('DD/MM/YYYY, HH:mm:ss'));
        })
        .catch((err) => console.log(err));
}

function ListOrders(props) {
  const [isLoading, setisLoading] = useState(true);
  const [orders, setorders] = useState(null);

  const [displayedorders, setdisplayedorders] = useState(null);

  const [isGettingData, setisGettingData] = useState(false);

  const [dates, setdates] = useState(
    moment().subtract(30, 'days').format('DD/MM/YYYY') +
      '-' +
      moment().add(1, 'day').format('DD/MM/YYYY')
  );

  const [search, setsearch] = useState('');

  const [lastupdate, setlastupdate] = useState(
    moment().format('DD/MM/YYYY, HH:mm:ss')
  );

  async function filter(dates, search) {
    console.log(dates);
    if (dates.length > 0 && dates.split('-')[1].length > 1) {
      await getOrders(
        props.currentUser,
        dates,
        props.value,
        setisLoading,
        setorders,
        setisGettingData,
        setlastupdate,
        setdisplayedorders
      );

      console.log(orders);
      const neworders = orders;

      if (search.length > 0) {
        neworders = neworders.filter((order) => {
          return (
            order.id.toString().includes(search) ||
            order.Nom.toUpperCase().includes(search.toUpperCase()) ||
            order.RaisonSociale.toUpperCase().includes(search.toUpperCase())
          );
        });
      }

      setdisplayedorders(neworders);
    }
  }

  function filtersearch(search) {
    setsearch(search.value);

    filter(dates, search.value);
  }

  function filterdates(dates) {
    setdates(dates.value);
    filter(dates.value, search);
  }

  useEffect(() => {
    props.currentUser.Code_Utilisateur &&
      getOrders(
        props.currentUser,
        dates,
        props.value,
        setisLoading,
        setorders,
        setisGettingData,
        setlastupdate,
        setdisplayedorders
      );
  }, [props.value, props.currentUser]);

  return (
    <Container>
      <div className='headerlist'>
        <div className='responsiveheadermargin'>
          <Search
            showNoResults={false}
            onSearchChange={(e, data) => filtersearch(data)}
          />
        </div>
        <div className='responsiveheadermargin'>
          <DatesRangeInput
            closeOnMouseLeave={false}
            dateFormat='DD/MM/YYYY'
            clearable
            hideMobileKeyboard={true}
            animation='fade'
            duration={200}
            value={dates}
            onChange={(e, data) => filterdates(data)}
          ></DatesRangeInput>
        </div>
        <div
          className='responsiveheadermargin'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            labelPosition='left'
            content={
              <>
                {'Last updated: ' + lastupdate}

                <FontAwesomeIcon
                  spin={isGettingData}
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  icon={faSyncAlt}
                ></FontAwesomeIcon>
              </>
            }
            onClick={() => {
              setisGettingData(true);
              getOrders(
                props.currentUser,
                dates,
                props.value,
                setisLoading,
                setorders,
                setisGettingData,
                setlastupdate,
                setdisplayedorders
              );
            }}
          ></Button>
        </div>
      </div>
      {isLoading && !isGettingData ? (
        <MyActivityIndicator />
      ) : (
        <>
          {orders && orders.length > 0 ? (
			<>
			<Message style={{ fontWeight: 'bold', textAlign: 'center' }}>
			  Orders Amount : {Math.round(Object.values(orders).reduce((r, { MontantAcompte }) => r + MontantAcompte, 0) * 100) / 100 + ' LYD'}<br />
			  Invoiced Amount : {Math.round(Object.values(orders).reduce((r, { montantFact }) => r + montantFact, 0) * 100) / 100 + ' LYD'}
			</Message>
            <List>
              {displayedorders.map((order) => (
                <List.Item key={order.id}>
                  <div className='responsivepadding'>
                    <Order order={order} />
                  </div>
                </List.Item>
              ))}
            </List>
			</>
          ) : (
            <Message> No orders matching these criterias.</Message>
          )}
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(ListOrders);
