import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faStore,
  faUser,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import {
  Grid,
  Segment,
  Responsive,
  Container,
  Statistic
} from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import './order.scss';
import moment from 'moment';
export default function Order(props) {
  const [hover, setHover] = useState(false);

  const { order } = props;
  return (
    <Link style={{ color: '#141414' }} to={'order/' + order.id}>
      <Grid.Row color='teal' style={{ padding: 10, overflow: 'auto' }}>
        <Segment>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Container>
              <Grid>
                <Grid.Row
                  verticalAlign='middle'
                  style={{
                    borderRadius: 5,
                    borderBottom:
                      order.status === 'accepted'
                        ? '1.5px solid #9fff87'
                        : order.status === 'rejected'
                        ? '1.5px solid #ff4747'
                        : '1.5px solid #00b5ad',
                    padding: 10,
                    backgroundColor: hover ? '#F2F1EF' : 'white',
                    cursor: 'pointer'
                  }}
                >
                  <Container>
                    <Grid>
                      <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column width={8} style={{ fontStyle: 'italic' }}>
                          Order #{order.id}
						  {order.numfact !== null ? (
							<span style={{ fontWeight: 'bold', color: 'orangered' }}>
							  {` Invoice #${order.numfact}`}
							</span>
						   ) : ('')}
                        </Grid.Column>
                        <Grid.Column width={8} style={{ textAlign: 'right' }}>
                          {moment(order.DateCreation)
                            .utc()
                            .format('DD/MM/YYYY, HH:mm:ss')}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column
                          width={3}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                          }}
                        >
                          <Responsive minWidth={600}>
                            {order.status === 'accepted' ? (
                              <FontAwesomeIcon
                                color='green'
                                icon={faCheck}
                                size='lg'
                                style={{ marginTop: 5, marginBottom: 5 }}
                              ></FontAwesomeIcon>
                            ) : order.status === 'rejected' ? (
                              <FontAwesomeIcon
                                color='red'
                                icon={faTimes}
                                size='lg'
                                style={{ marginTop: 5, marginBottom: 5 }}
                              ></FontAwesomeIcon>
                            ) : (
                              <FontAwesomeIcon
                                color='grey'
                                icon={faChevronRight}
                                size='lg'
                                style={{ marginTop: 5, marginBottom: 5 }}
                              ></FontAwesomeIcon>
                            )}
                          </Responsive>
                        </Grid.Column>

                        <Grid.Column
                          style={{ fontSize: 18 }}
                          width={10}
                          textAlign='left'
                        >
                          <Grid.Row columns={2} style={{ padding: 10 }}>
                            <Container>
                              <Grid>
                                <Grid.Column
                                  style={{ textAlign: 'center' }}
                                  width={2}
                                >
                                  <FontAwesomeIcon
                                    icon={faUser}
                                  ></FontAwesomeIcon>
                                </Grid.Column>
                                <Grid.Column width={14}>
                                  {order.Nom}
                                </Grid.Column>
                              </Grid>
                            </Container>
                          </Grid.Row>
                          <Grid.Row style={{ padding: 10 }}>
                            <Container>
                              <Grid>
                                <Grid.Column
                                  style={{ textAlign: 'center' }}
                                  width={2}
                                >
                                  <FontAwesomeIcon
                                    icon={faStore}
                                  ></FontAwesomeIcon>
                                </Grid.Column>
                                <Grid.Column width={14}>
                                  ({order.Code_Client}) {order.RaisonSociale}
                                </Grid.Column>
                              </Grid>
                            </Container>
                          </Grid.Row>
                        </Grid.Column>
                        <Grid.Column
                          width={3}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Statistic size='tiny'>
                            <Statistic.Value>
                              {order.MontantAcompte} LYD{' '}
                            </Statistic.Value>
							<Statistic.Value style={{ color: 'orangered' }}>
                              {order.montantFact !== null ? `${order.montantFact} LYD` : ''}
                            </Statistic.Value>
                            <Statistic.Label className='label'>
                              {order.quantity} article
                              {order.quantity > 1 && 's'}
                            </Statistic.Label>							
                          </Statistic>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Container>
                </Grid.Row>
              </Grid>
            </Container>
          </div>
        </Segment>
      </Grid.Row>
    </Link>
  );
}
