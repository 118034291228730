import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import RoutingComponent from './Routing';

import { Provider } from 'react-redux';
import store from './store/store';

export default class App extends React.Component {
  render() {
    return (
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: '#ECF0F1',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Provider store={store}>
          <Router>
            <RoutingComponent />
          </Router>
        </Provider>
      </div>
    );
  }
}
