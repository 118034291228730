import AppLayout from "./AppLayout";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedAppRoute = ({ component, ...routeProps }) => {
  const isAuth = localStorage.getItem("user");
  return (
    <Route
      {...routeProps}
      render={props => {
        return isAuth ? (
          <AppLayout {...props} {...routeProps}>
            {React.createElement(component, props)}
          </AppLayout>
        ) : (
          <Redirect to={"/login"}></Redirect>
        );
      }}
    />
  );
};

export default ProtectedAppRoute;
